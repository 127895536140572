import type { TableOrder } from './sorting'
import React, { useEffect, useCallback } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import MuiTablePagination from '@mui/material/TablePagination'
import { useBreakPoints } from '../../lib/hooks'
import { getComparator } from './sorting'

interface TablePaginationActionsProps {
    count: number
    page: number
    rowsPerPage: number
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

const TablePaginationActions = ({
    count,
    page,
    rowsPerPage,
    onPageChange,
}: TablePaginationActionsProps): JSX.Element => {
    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, 0)
    }

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1)
    }

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1)
    }

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
        <Box sx={{ flexShrink: 0, ml: { xs: 1, sm: 2.5 } }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                <FirstPageIcon />
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </Box>
    )
}

type TablePaginationProps<T1> = {
    rowCount: number
    rowsPerPage: number
    page: number
    rows: T1[]
    tableOrder: TableOrder<T1>[]
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>
    setPage: React.Dispatch<React.SetStateAction<number>>
    setVisibleRows: React.Dispatch<React.SetStateAction<T1[] | null>>
}
const TablePagination = <T1,>({
    rowCount,
    page,
    rowsPerPage,
    tableOrder,
    rows,
    setVisibleRows,
    setPage,
    setRowsPerPage,
}: TablePaginationProps<T1>): JSX.Element => {
    const { isMobile } = useBreakPoints()

    // reset page when rows change from filtering
    useEffect(() => {
        setPage(0)
    }, [rows.length, setPage])

    useEffect(() => {
        let rowsOnMount = [...rows]
        if (tableOrder.length) rowsOnMount = rows.slice().sort(getComparator<T1, keyof T1>(tableOrder))
        rowsOnMount = rowsOnMount.slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage)
        setVisibleRows(rowsOnMount)
    }, [tableOrder, rows, rowsPerPage, setVisibleRows])

    const handleChangePage = useCallback(
        (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
            setPage(newPage)
            let sortedRows = [...rows]
            if (tableOrder.length) sortedRows = rows.slice().sort(getComparator<T1, keyof T1>(tableOrder))
            const updatedRows = sortedRows.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage)
            setVisibleRows(updatedRows)
        },
        [setPage, rows, tableOrder, rowsPerPage, setVisibleRows]
    )

    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const updatedRowsPerPage = parseInt(event.target.value, 10)
            setRowsPerPage(parseInt(event.target.value, 10))
            setPage(0)
            let sortedRows = [...rows]
            if (tableOrder.length) sortedRows = rows.slice().sort(getComparator<T1, keyof T1>(tableOrder))
            const updatedRows = sortedRows.slice(0 * updatedRowsPerPage, 0 * updatedRowsPerPage + updatedRowsPerPage)
            setVisibleRows(updatedRows)
        },
        [setRowsPerPage, setPage, rows, tableOrder, setVisibleRows]
    )

    const options: Set<number> = new Set([rowsPerPage, 15, 30, 100])
    return (
        <MuiTablePagination
            sx={{
                '.MuiTablePagination-spacer': { flex: { xs: 'none', md: '1 1 100%' } },
                '.MuiInputBase-root.MuiInputBase-colorPrimary': {
                    marginRight: { xs: 1, sm: '32px' },
                },
                borderBottom: 'none',
            }}
            component="div"
            rowsPerPageOptions={[...Array.from(options)]}
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
                inputProps: {
                    'aria-label': 'rows per page',
                },
                native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            labelRowsPerPage={isMobile ? 'Rows' : 'Rows per page:'}
        />
    )
}

export default TablePagination
