import type { SystemStyleObject } from '@mui/system'
import type { Select, TableProps } from '../../lib/types/tableConfigTypes'
import React from 'react'
import MuiTableCell from '@mui/material/TableCell'

type TableCellProps<T, T2> = {
    row: T
    rows: T[]
    index: number
    select?: Select<T, T2>
    format?:
        | SystemStyleObject
        | ((entry: T, tableProps: TableProps<T, T2>, index: number, self: T[]) => SystemStyleObject | undefined)
    numeric?: boolean | ((entry: T, tableProps: TableProps<T, T2>) => boolean | undefined)
    emptyValue: React.ReactNode
    tableProps: TableProps<T, T2>
    borderLeft?: boolean
    colSpan?: number | ((entry: T, tableProps: TableProps<T, T2>) => number | undefined)
    className?: string
}

const TableCell = <T, T2>({
    row,
    rows: self,
    index,
    select,
    format,
    numeric,
    emptyValue,
    tableProps,
    borderLeft,
    colSpan,
    className,
}: TableCellProps<T, T2>): JSX.Element | null => {
    const cellValue = select?.(row, index, tableProps, self)
    const cellStyles = typeof format === 'function' ? format(row, tableProps, index, self) : format
    const colSpanVal = typeof colSpan === 'function' ? colSpan(row, tableProps) : colSpan
    const numericVal = typeof numeric === 'function' ? numeric(row, tableProps) : numeric

    return colSpanVal === 0 ? null : (
        <MuiTableCell
            sx={({ spacing }) => ({
                '@media print': { paddingX: spacing(1.5), paddingY: spacing(0.5), fontSize: '18px' },
                borderLeft: borderLeft ? '1px solid' : undefined,
                borderColor: 'divider',
                ...cellStyles,
            })}
            align={numericVal ? 'right' : 'left'}
            colSpan={colSpanVal}
            className={className}
        >
            {cellValue === null || cellValue === undefined ? emptyValue : cellValue}
        </MuiTableCell>
    )
}

export default TableCell
